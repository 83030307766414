::root {
    --color-background: #f0f0f7;
    --color-primary-lighter: #f5477e;
    --color-primary-light: #8bbdc8;
    --color-primary: #378899;
    --color-primary-dark: #ec6559;
    --color-primary-darker: #6842c2;
    --color-secundary: #f3a769;
    --color-secundary-dark: #04bf58;
    --color-title-in-primary: #ffffff;
    --color-text-in-primary: #d4c2ff;
    --color-text-title: #32264d;
    --color-text-complement: #9c98a6;
    --color-text-base: #6a6180;
    --color-line-in-white: #e6e6f0;
    --color-input-background: #fff;
    --color-button-text: #ffffff;
    --color-box-base: #ffffff;
    --color-box-footer: #fafafc;
    --pauta: #e9483e;
    --apuracao: #ea6659;
    --edicao: #f39f59;
    --jornalista: #7cc0ca;
    --opniao: #008a99;
    --outros: #008a99;
    font-size: 25px;
}

@font-face {
    font-family: "Ebrima";
    src: url("../fonts/ebrima/ebrima.ttf") format("truetype");
}

@font-face {
    font-family: "Ebrima Bold";
    src: url("../fonts/ebrima/ebrima-bold.ttf") format("truetype");
}

@font-face {
    font-family: "Etna";
    src: url("../fonts/etna/etna-free-font.otf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: var(--color-background);
}

html,
body,
#root ::selection {
    /* background-color: #71A9FC; */
    background: #f0f2f5;
    color: #71a9fc;
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-layout{
    background-color: #2e8b9a2e
}
.buttonVoice {
    background: #f0f2f5 !important;
    color: #71a9fc;
}
body,
input,
button,
textarea {
    font: 500 1.9rem Ebrima;
}

.container {
    width: 90vw;
    max-width: 700px;
}

.ant-typography {
    font-size: 2.2rem;
}

@media (min-width: 700px) {
    :root {
        font-size: 62.5%;
    }
}
