#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-background {
    background: #fff;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    padding-bottom: 30px;
}

.card {
    width: 200px;
    margin: 10px;
    border: 1px solid #ccc;
  
}

.card img {
    max-width: 100%;
}

.card .text {
    padding: 0 20px 20px;
}

.card .text>button {
    background: gray;
    border: 0;
    color: white;
    padding: 10px;
    width: 100%;
}

.card:nth-child(1) {
    width: 420px;
}

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
  }
  #components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }